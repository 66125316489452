export const menuItems = [
  {
    name: '#oferta',
    label: 'Nasza oferta',
  },
  {
    name: '#about',
    label: 'O nas',
  },
  {
    name: '#contact',
    label: 'Kontakt',
  },
]
