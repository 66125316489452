import styled from 'styled-components'
import { space } from 'styled-system'

const List = styled.ul`
  ${space}
  li {
    color: ${({ theme, color }) => theme.colors[color] || theme.colors.text};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 28px;
    margin-left: 20px;
    margin-bottom: 17px;
    display: flex;
    &:before {
      content: '';
      min-width: 10px;
      max-width: 10px;
      min-height: 10px;
      max-height: 10px;
      background-color: ${({ theme, color }) =>
        theme.colors[color] || theme.colors.dark};
      display: block;
      border-radius: 500px;
      margin-right: 15px;
      margin-top: 10px;
    }
  }
`

export default List
