import React from 'react'
import { Link } from 'gatsby'
import { Box } from '../Core'

import imgL1Logo from '../../assets/image/svg/logo-black.svg'
import imgL1LogoWhite from '../../assets/image/svg/logo-white.svg'

const Logo = ({ white, height, className = '', ...rest }) => {
  return (
    <Link to="/" aria-label="strona główna" className={`${className}`} {...rest}>
      {white ? (
        <Box as="img" width="100%" src={imgL1LogoWhite} alt="" />
      ) : (
        <Box as="img" width="100%" src={imgL1Logo} alt="" />
      )}
    </Link>
  )
}

export default Logo
