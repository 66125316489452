import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Box, Span, Text } from '../Core'
import Logo from '../Logo'

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? 'dark' : 'light'}>
        <Container id="contact">
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-start">
              <Col lg="2" md="4">
                <Logo white={isDark} />
              </Col>
              <Col lg="4" md="4">
			
                <Text variant="small" color="light" mt={[4, null, 0, null]}>
                  Blackcode Capital Sp. z o.o.
                  <br />
                  Aleja Wielkopolska 29/7, 60-603 Poznań, Polska
                  <br />
                  <Span fontWeight="bold">NIP</Span>: 7811979369,{' '}
                  <Span fontWeight="bold">REGON</Span>: 381022236
                  <br />
                  <Span fontWeight="bold">Email</Span>: biuro@blackcodecapital.pl
                  <br />
                  <Span fontWeight="bold">Tel</Span>: 510 290 568
                </Text>
              </Col>
            </Row>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Footer
